.cardDraw {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.cardsWrapper {
  display: flex;
  align-items: center;
  gap: 10px; /* Простір між картками і іконкою */
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.card {
  width: 100px;
  height: 150px;
  perspective: 1000px;
  cursor: pointer;
  position: relative;
}

.cardBack,
.cardFront {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  transition: transform 0.6s;
  box-sizing: border-box;
  overflow: hidden;
}

.cardBack {
  background-image: url('C:\Users\AndreyKulish\Documents\vscode_projects\retropaw-main\my-app\public\cardback.png'); /* Задайте шлях до вашого зображення карти */
  background-size: cover;
  background-position: center;
}

.cardFront {
  background-image: url('C:\Users\AndreyKulish\Documents\vscode_projects\retropaw-main\my-app\public\cardfront.png'); /* Задайте шлях до вашого зображення карти */
  background-size: cover;
  background-position: center;
  transform: rotateY(180deg);
  flex-direction: column;
  padding: 5px;
}

.cardFront img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 5px;
}

.cardFront p {
  font-size: 12px;
  text-align: center;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.card.revealed .cardBack {
  transform: rotateY(180deg);
}

.card.revealed .cardFront {
  transform: rotateY(0);
}

.shuffleIcon {
  width: 40px; /* Ширина іконки */
  height: 50px; /* Висота іконки */
  cursor: pointer; /* Вказівник курсору при наведенні */
  transition: transform 0.3s;
}

.shuffleIcon:hover {
  transform: scale(1.1); /* Збільшення іконки при наведенні */
}