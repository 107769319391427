.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2f2f2; /* або інший колір фону */
  }
  
  .loginBox {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 2px;
    box-shadow: 0 4px 8px rgba(0, 
  0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  }
  
  .amoRetroTitle {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
  }
  
  .googleSignInButton {
  background-color: #4285F4;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
  display: block;
  }
  
  .googleSignInButton:hover {
  background-color: #357ae8;
  }