.dashboard {
  background: #f4f7f6;
  min-height: 100vh;
  padding: 2rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.boardSection {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.addButton {
  width: 150px;
  height: 168.9px;
  border: 2px dashed #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background: #fff;
  cursor: pointer;
}

.addButton:hover {
  background-color: #e8e8e8;
}

.boardLink {
  text-decoration: none;
  color: inherit;
}

.boardLink:hover .board {
  background-color: #e8e8e8;
}

.board {
  width: 250px;
  padding: 1rem;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.name {
  font-size: x-large;
  color: black;
  margin: 20px 0;
}

.dateCount {
  display: flex;
  justify-content: space-between;
}

.cardCount, .date {
  font-size: 14px;
  color: #666;
  margin: 5px 0;
}

.divider {
  height: 0.5px; /* Висота лінії */
  background-color: #ccc; /* Колір лінії */
  margin: 0 0; /* Відступи зверху та знизу для лінії */
}

.boardFooter {
  display: flex;
  justify-content: space-between; /* Вирівнює елементи по різних кінцях контейнера */
  padding: 5px 20px 5px 20px;
  align-items: center; /* Вирівнює елементи по центру по вертикалі */
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.boardMenu {
  position: relative;
  cursor: pointer; /* Додає вказівник руки при наведенні */
  color:#5e21d7;
  padding: 1px 8px;
}

.boardMenu:hover {
  background-color: #e8e8e8;
}

.hidden {
  display: none; /* забезпечує, що меню залишається прихованим, коли воно не активне */
}

/* Стилі для boardActions, спливаючого меню */
.boardActions {
  width: max-content;
  position: absolute;
  right: 0;
  top: 100%; /* Відображення під boardMenu */
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  z-index: 10; /* Забезпечує відображення поверх інших елементів */
}

.boardActions button {
  display: block;
  background: none;
  border: none;
  padding: 10px 10px;
  cursor: pointer;
  text-align: left;
  width: 100%; /* Повна ширина для кращого натискання */
  font-size: 14px;
}

.boardActions button:hover {
  background-color: #f0f0f0;
}