/* BoardPage.module.css */
.boardPage {
  padding: 20px 20px 20px 20px;
  /* Задаємо фоновий колір для всієї сторінки */
  background-color: #ececec;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header h1 {
  margin: 10px;
}

.headerButtons {
  /* Щоб кнопки були в ряд */
  display: flex;
}

.headerButtons button {
  margin-right: 10px;
  /* Фіолетовий колір кнопок */
  background-color: #5e21d7;
  /* Білий текст на кнопках */
  color: white;
  /* Відсутність рамки */
  border: none;
  /* Падінги для кнопок */
  padding: 5px 10px;
  /* Злегка закруглені кути */
  border-radius: 2px;
  /* Вказівник курсору при наведенні */
  cursor: pointer;
  font-family: inherit;
}

.headerButtons button:hover {
  /* Трохи світліший відтінок фіолетового при наведенні */
  background-color: #7f3bd8;
}

.timerContainer {
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Спочатку контейнер невидимий */
  visibility: hidden;
}

.timerContainer.active {
  visibility: visible;
  /* Показувати контейнер коли таймер активний */
}

.filterToggleButton {
  min-width: 100px;
  padding: 5px 10px;
  margin-right: 10px;
  /* Синій колір фону */
  background-color: #5e21d7;
  /* Білий колір тексту */
  color: white;
  /* Видалення стандартної рамки */
  border: none;
  /* Закруглення кутів */
  border-radius: 2px;
  /* Вказівник курсору при наведенні */
  cursor: pointer;
  font-family: inherit;
}

.filterToggleButton:hover {
  /* Темніший синій колір фону при наведенні */
  background-color: #7f3bd8;
}

.divider {
  margin-top: 50px;
  /* Висота лінії */
  height: 0.5px;
  /* Колір лінії */
  background-color: #ccc;
  /* Відступи зверху та знизу для лінії */
  margin: 0 0;
}

.columns {
  display: flex;
  /* Замінено на space-between для рівномірного розподілу колонок */
  justify-content: space-between;
  /* Встановлено ширину контейнера колонок на 100% */
  width: 100%;
}

.column {
  flex: 1;
  /* Кожна колонка займає рівний простір */
  margin-right: 20px;
  min-width: 0;
  /* Додано, щоб запобігти переповненню контенту колонки */
}

.column h2 {
  color: darkslategrey;
}

/* Останній елемент колонки не має margin справа */
.column:last-child {
  margin-right: 0;
}

.card {
  display: flex;
  /* Організуємо вміст картки у вертикальному напрямку */
  flex-direction: column;
  /* Розподіляємо вміст на весь доступний простір */
  justify-content: space-between;
  background-color: #f4f4f4;
  position: relative;
  border: 2px solid #f4f4f4;
  border-radius: 2px;
  padding: 10px;
  margin-bottom: 10px;
  /* М'яка тінь для карток */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
}

.cardContent {
  display: flex;
  /* Організуємо вміст картки у вертикальному напрямку */
  flex-direction: column;
  /* Розподіляємо вміст на весь доступний простір */
  justify-content: space-between;
  /* Забезпечує, що контент займає всю висоту картки */
  height: 100%;
}

.cardContent p {
  /* Дозволяє переносити текст на новий рядок */
  word-wrap: break-word;
  /* Сучасний аналог word-wrap для кращої підтримки */
  overflow-wrap: break-word;
  /* Обмежує максимальну ширину тексту, щоб він не виходив за межі контейнера */
  max-width: 100%;
  padding-right: 20px;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.cardText {
  white-space: pre-wrap;
}

.cardFooter {
  display: flex;
  /* Вирівнює елементи по різних кінцях контейнера */
  justify-content: space-between;
  /* Вирівнює елементи по центру по вертикалі */
  align-items: center;
}

.ownerCard {
  /* Зелена рамка */
  border: 2px solid #5e21d7;
  /* Світлий зелений фон */
  background-color: #f4f4f4;
}

.cardAuthor {
  /* Вирівнює автора до лівого краю */
  align-self: flex-start;
  /* Зміщує автора вниз картки */
  margin-top: auto;
  font-size: 12px;
  padding-top: 5px;
  color: darkslategrey;
}

.cardMenu {
  position: absolute;
  /* Змініть згідно з потребами */
  top: 10px;
  /* Змініть згідно з потребами */
  right: 10px;
  /* Додає вказівник руки при наведенні */
  cursor: pointer;
  color: #5e21d7;
  padding: 0 8px;
}

.cardMenu:hover {
  background-color: #e8e8e8;
}

.hidden {
  /* забезпечує, що меню залишається прихованим, коли воно не активне */
  display: none;
}

/* Стилі для boardActions, спливаючого меню */
.cardActions {
  width: max-content;
  position: absolute;
  right: 0;
  /* Відображення під boardMenu */
  top: 100%;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  /* Забезпечує відображення поверх інших елементів */
  z-index: 10;
}

.cardActions button {
  display: block;
  background: none;
  border: none;
  padding: 10px 10px;
  cursor: pointer;
  text-align: left;
  /* Повна ширина для кращого натискання */
  width: 100%;
  font-size: 14px;
}

.cardActions button:hover {
  background-color: #f0f0f0;
}

.selectedAvatar,
.unselectedAvatar {
  width: 48px;
  height: 48px;
  /* Зробити зображення круглим */
  border-radius: 50%;
  margin-right: -8px;
  /* Для використання z-index */
  position: relative;
}

.selectedAvatar {
  border: 3px solid #5e21d7;
  /* Вищий індекс для вибраного фото */
  z-index: 1;
}

.unselectedAvatar {
  /* Прозора рамка */
  border: 2px solid white;
}

.selectedAvatar:hover,
.unselectedAvatar:hover {
  /* Збільшення z-index при наведенні */
  z-index: 2;
}

.newCardInput {
  /* Встановлюємо flexbox */
  display: flex;
  /* Стовпцевий макет */
  flex-direction: column;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 8px 24px 8px 8px;
  background-color: #fff;
  border-radius: 2px;
  position: relative;
}

.newCardInput textarea {
  /* Ширина поля введення */
  width: 100%;
  /* Мінімальна висота поля */
  min-height: 50px;
  border: none;
  outline: none;
  resize: none;
  /* Внутрішній відступ для поля введення */
  padding: 8px;
  font-family: inherit;
  /* Розмір шрифту */
  font-size: 14px;
  /* Розміри поля включають padding і border */
  box-sizing: border-box;
}

.newCardInput input {
  /* Поле введення займає всю доступну ширину */
  width: 100%;
  /* Додаємо відстань між полем введення та кнопками */
  margin-right: 8px;
  border: none;
  outline: none;
}

.buttonContainer {
  /* Встановлюємо flexbox для кнопок */
  display: flex;
  /* Вирівнювання кнопок праворуч */
  justify-content: flex-end;
  padding-top: 8px;
}

.newCardInputButton {
  font-size: 12px;
  background-color: #5e21d7;
  color: white;
  padding: 3px 3px;
  border: none;
  cursor: pointer;
  border-radius: 1px;
}

.newCardInputButton:not(:last-child) {
  /* Відстань між кнопками, крім останньої */
  margin-right: 2px;
}

.newCardInputButton:hover {
  /* Світліший відтінок фіолетового при наведенні */
  background-color: #7f3bd8;
}

.addCardButton {
  /* Показати кнопку як блочний елемент */
  display: block;
  /* Встановити ширину кнопки на 100% від контейнера */
  width: 100%;
  /* Відступи зверху та знизу */
  padding: 10px 0;
  /* Відстань від верхнього елементу */
  margin-top: 10px;
  margin-bottom: 10px;
  /* Видалити рамку */
  border: none;
  /* Фіолетовий фон кнопки */
  background-color: #5e21d7;
  /* Білий текст */
  color: white;
  /* Жирний текст */
  font-weight: bold;
  /* Текст по центру */
  text-align: center;
  /* Закруглені кути */
  border-radius: 2px;
  /* Вказівник курсору */
  cursor: pointer;
  /* Плавна зміна фону при наведенні */
  transition: background-color 0.3s;
}

.addCardButton:hover {
  /* Світліший фіолетовий колір при наведенні */
  background-color: #7f3bd8;
}

.addCardButton:focus {
  /* Видалити контур при фокусі */
  outline: none;
}

/* Стиль для іконки '+' усередині кнопки */
.addCardButton:before {
  /* Додати знак '+' */
  content: '+';
  /* Показати як інлайновий блок */
  display: inline-block;
  /* Відстань справа від іконки */
  margin-right: 5px;
}

.emojiPickerToggle {
  position: absolute;
  right: 8px;
  top: 8px;
  border: none;
  font-size: 14px;
  cursor: pointer;
  background: transparent;
}

.emojiPicker {
  position: absolute;
  right: 0;
  /* Відступ зверху, щоб не перекривати textarea */
  top: 40px;
  /* Забезпечити відображення пікера над іншими елементами */
  z-index: 2;
}

.emojiPickerContainer {
  position: relative;
}

/* Стилі для самого попапа емодзі-пікера */
.emojiPickerPopup {
  top: 2px;
  left: calc(100% - 5px);
  position: absolute;
  /* Забезпечує, що попап буде відображатися над іншими елементами */
  z-index: 1000;
}

.newCardInput {
  /* Встановлення відносної позиції для позиціонування emojiPicker */
  position: relative;
}