.timerDisplay {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #5e21d7;
  color: white;
  padding: 5px 10px;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 10px; /* Відступ з правого боку */
  font-size: 14px;
}

.timerDisplay:hover {
  background-color: #7f3bd8;
}

.pauseIcon {
  display: inline-block;
  margin-right: 8px; /* Додайте відступ справа для відокремлення іконки від тексту */
  font-size: 14px; /* Розмір іконки */
  line-height: 1; /* Забезпечує відсутність додаткового відступу над і під іконкою */
  vertical-align: middle; /* Вирівнює іконку по вертикалі з текстом */
  color: #ffffff; /* Колір іконки, змініть на потрібний вам */
}