.homePage {
    width: 100%;
    box-sizing: border-box;
  }
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between; /* This will position the content and image apart */
    background-color: #ffffff; /* Задаємо фоновий колір для всієї сторінки */
    text-align: center;
    padding: 50px;
  }

  .headerContent {
    /* If you want the text part to take more space, you can use flex-basis or flex-grow */
    flex-basis: 60%; /* Adjust as needed */
  }

  .headerImage {
    width: 40%; /* Adjust as needed */
    height: auto; /* This will maintain the aspect ratio of the image */
    /* Add some left margin if you want to separate it a bit from the text content */
    margin-left: 20px;
  }

  @media (max-width: 768px) {
    .header {
      flex-direction: column;
    }
    .headerImage {
      width: 100%;
      margin-left: 0;
      margin-top: 20px; /* Add some top margin to separate it from the text */
    }
  }
  
  .header h1 {
    font-size: 2.5em;
    color: #333; /* Приклад кольору тексту, замініть на актуальний */
  }
  
  .header p {
    font-size: 1.2em;
    margin-bottom: 30px;
  }
  
  .getStartedButton {
    background-color: #5e21d7; /* Приклад кольору кнопки, замініть на актуальний */
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1.1em;
    cursor: pointer;
    border-radius: 2px;
  }
  
  .getStartedButton:hover {
    background-color: #7f3bd8; /* Приклад кольору кнопки при наведенні, замініть на актуальний */
  }