.popup {
    position: absolute;
    /* Ви можете налаштувати положення, як вам потрібно */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #000;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    z-index: 1000;
  }

  .share {
    margin-right: 10px;
    background-color: #5e21d7; /* Фіолетовий колір кнопок */
    color: white; /* Білий текст на кнопках */
    border: none; /* Відсутність рамки */
    padding: 5px 10px; /* Падінги для кнопок */
    border-radius: 2px; /* Злегка закруглені кути */
    cursor: pointer; /* Вказівник курсору при наведенні */
  }
  
  .share:hover {
    background-color: #7f3bd8; /* Трохи світліший відтінок фіолетового при наведенні */
  }