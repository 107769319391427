.profilePage {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .profileForm {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .formGroup label {
    font-weight: bold;
  }
  
  .formGroup input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .formGroup input:focus {
    border-color: #007bff;
  }
  
  .formGroup span {
    padding: 10px;
    background-color: #e9ecef;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .updateButton {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s;
  }
  
  .updateButton:hover {
    background-color: #0056b3;
  }
  
  h1 {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
  }

.logoutButton {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s;
}

.logoutButton:hover {
  background-color: #0056b3;
}