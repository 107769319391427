.timerPopup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Щоб було над іншими елементами */
}

.timerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.timerContent p {
    margin-top: 0;
    margin-bottom: 5;
}

.timerContent input[type="number"] {
    margin-bottom: 10px;
    width: 60px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 2px;
    font-size: 16px;
}

.timerContent button {
    margin: 5px;
    padding: 5px 10px;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    background-color: #5e21d7;
    color: white;
}

.timerContent button:hover {
    background-color: #7f3bd8;
}