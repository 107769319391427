.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #333;
  color: white;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
}

.navbarContent {
  display: flex;
  align-items: center;
}

.icon {
  width: 32px;
  height: 32px;
  margin-left: 24px;
}

.navbarTitle {
  font-size: 150%;
  padding: 12px 8px;
  color: white;
  text-decoration: none;
}

.navbarCenter {
  font-size: 120%;
  color: white;
  text-decoration: none;
}

.userPhoto {
  width: 32px; /* або будь-який інший розмір */
  height: 32px; /* або будь-який інший розмір */
  border-radius: 50%; /* зробити зображення круглим */
  margin-right: 8px;
}

.navbarUser {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  color: white;
  text-decoration: none;
  font-size: 100%;
}