/* Стилі для кнопки голосування */
.voteButton {
    background: none;
    border: none;
    cursor: pointer;
    /* Розмір іконки */
    font-size: 14px;
    color: #5e21d7;
  }
  
  /* Стилі для іконки при наведенні */
  .voteButton:hover {
    /* Додано клас 'voted' для наведення */
    /* Змінюємо колір при наведенні */
    color: #7f3bd8;
  }
  
  .voteContainer {
    display: flex;
    /* Вирівнює елементи голосування до правого краю */
    justify-content: flex-end;
    /* Вирівнює елементи по центру по вертикалі */
    align-items: center;
    font-size: 14px;
  }